"use client";

import React, { createContext, ReactNode, useContext } from "react";

import { StrapiMenuResponse } from "@/data/strapi";

interface MenuContextInterface {
  menu?: StrapiMenuResponse["data"];
}

interface Props {
  menu?: StrapiMenuResponse["data"];
  children?: ReactNode | undefined;
}

export const MenuContext = createContext<MenuContextInterface>({});

export const MenuProvider = ({ menu, children }: Props) => {
  return <MenuContext.Provider value={{ menu }}>{children}</MenuContext.Provider>;
};

export const useMenu = () => {
  const context = useContext(MenuContext);

  if (context === null) {
    throw new Error("useMenu must be used within a MenuProvider");
  }

  return context;
};
